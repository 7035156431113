 // navbar
.open_sideNavPanel {
    font-size: 40px;
    margin-left: 20px;
    font-weight: 300;
    color: white;
    cursor: pointer;
}

// nav-toggler  
.nav-toggler {
    width: 48px;
    height: 36px;
    position: relative;
    left: 35px;
    top: 35px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    cursor: pointer;
}

@media(min-width: 600px) {
    .nav-toggler.nav-toggler-open {
        left: 290px;
    }
}

@media(max-width: 600px) {
    .nav-toggler.nav-toggler-open span {
        background: #5e5b5b !important; 
    }
    
    .nav-toggler.nav-toggler-open {
       z-index: 5;
    }
}

.nav-toggler span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: #c9bfbb;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.nav-toggler span:nth-child(even) {
    left: 50%;
    border-radius: 0 7.2px 7.2px 0;
}

.nav-toggler span:nth-child(odd) {
    left: 0px;
    border-radius: 7.2px 0 0 7.2px;
}

.nav-toggler span:nth-child(1), .nav-toggler span:nth-child(2) {
    top: 0px;
}

.nav-toggler span:nth-child(3), .nav-toggler span:nth-child(4) {
    top: 14.4px;
}

.nav-toggler span:nth-child(5), .nav-toggler span:nth-child(6) {
    top: 28.8px;
}

.nav-toggler.nav-toggler-open span:nth-child(1), .nav-toggler.nav-toggler-open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav-toggler.nav-toggler-open span:nth-child(2), .nav-toggler.nav-toggler-open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav-toggler.nav-toggler-open span:nth-child(1) {
    left: 4px;
    top: 5.6px;
}

.nav-toggler.nav-toggler-open span:nth-child(2) {
    left: calc(50% - 4px);
    top: 5.6px;
}

.nav-toggler.nav-toggler-open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

.nav-toggler.nav-toggler-open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

.nav-toggler.nav-toggler-open span:nth-child(5) {
    left: 4px;
    top: 23.2px;
}

.nav-toggler.nav-toggler-open span:nth-child(6) {
    left: calc(50% - 4px);
    top: 23.2px;
}
  
//

.sideNavPanel {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(238, 238, 238);
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 75px;
}

.sideNavPanel-open {
    width: 260px;
}

.sideNavPanel a {
    text-align: center;
    padding: 10px 10px 10px;
    text-decoration: none;
    font-size: 26px;
    color: #3f3f3f;
    display: block;
    transition: 0.3s;
}

.sideNavPanel a:hover {
    color: rgb(180, 138, 23);
}

a.sideNavPanel-link-active {
    color: rgb(180, 138, 23);
    text-decoration: underline;
}

.sideNavPanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sideNavPanel {padding-top: 15px;}
    .sideNavPanel a {font-size: 18px;}
}

@media(max-width: 600px) {
    .sideNavPanel-open {
        width: 100%;
    }
}

footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: rgba(247, 247, 247, 0.041);
}

footer > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

footer span {
    color:rgb(184, 179, 179);
}

footer a {
    text-decoration: none;
    color:rgb(184, 179, 179);
    &:visited{
        color:rgb(184, 179, 179);
    }
    &:hover{
        color:rgb(255, 255, 255);
    }
}

footer a > svg {
    vertical-align: middle;
    margin: 0 0 2px 1px;
}

@media(max-width: 600px) {
    footer span {
        font-size: 0.88em;
    }

    footer a > svg {
        height: 1.2em;
        width: 1.2em;
    }
}

html, body {
    min-height: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

body {
    background: #3f4c6b;
    background: linear-gradient(to right, #3f4c6b, #606c88);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#root, #root>div {
    min-height: 100%;
    height: 100%;
}

.main{
    min-height: 100%;
    position: relative;  
}

.main-centre {
    padding-bottom: 70px;
}

.home {
    height: 100%;
    width: 100%;
    padding-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-detail-buttons {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.home-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}

.home-detail > div:first-child {
    min-width: 200px;
    min-height: 200px;
}

.home-detail-phrase {
    text-align: center;
    margin-top: 40px;
    animation: 1s ease-out 0s 1 slidePhrase;
}

@keyframes slidePhrase {
    0% {
      transform: translateY(150%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

.home-detail-phrase > span {
    color: rgba(238, 238, 238, 0.726);
}

.home-detail-button > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-detail-button > div > span {
    margin: 0 10px;
}

.home-detail-button {
   font-size: 14px;
   font-family: 'Montserrat', sans-serif;
   font-weight: 200;
   cursor: pointer;
   width: 160px;
   height: 50px;
   margin: 10px;
   text-align: center;
   text-transform: uppercase;
   color: white; 
   box-shadow: 0 0 4px rgb(172, 166, 166);           
   border-style: solid;
   border-width: 0.5px;
   border-radius: 50px;
   background: transparent;
   border-color: white;
   transition: 0.4s ease-in-out;
   outline: none;
 }

 .home-detail-button:hover {
   color: #fff;
   text-decoration: none;
   box-shadow: none;
 }

 .home-detail-button-play {
    color: rgb(223, 166, 10);
    border-color: rgb(223, 166, 10);
    box-shadow: 0 0 4px rgb(233, 205, 145);
 }

 .home-detail-button-play:hover {
    color: #fff;
    background-color: rgb(223, 166, 10);
    border-color: rgb(168, 123, 26);
 }

 .home-detail-button-rules:hover {
    color: rgb(100, 100, 98);
    background-color:#fff;
    border-color: rgb(114, 113, 108);
 }

 @media(min-width: 600px) and (min-height: 710px) {
    .home-detail {
        margin-top: 70px;
    }
 }

 @media(max-width: 600px) {
    .home-detail-buttons {
        flex-direction: column;
    }
 }