:root {
    --max-card-width: 454px;
    --max-card-height: 697px;
    --ratio: 0.65;
}
  

.mixinCard{
    align-self: center;
    grid-area: card;
    max-width: var(--max-card-width);
    max-height: var(--max-card-height);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    overflow-y: overlay;
    overflow-x: hidden;
    border: 1px solid lightgrey;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    position: relative;
    &::-webkit-scrollbar {
        width: 4px;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        border-radius: 4px;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(204, 204, 204); 
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(173, 173, 173); 
    }
}

.mixinCard > div:not(:first-child) {
    margin: 10px 15px;
}

.game{
    display: grid;
    max-height: 100vh;
    justify-items: center;
    align-items: center;
    justify-content: center;
    /* grid-auto-flow: row; */
    grid-template-areas: 
        "cardsleft currentpoints timer"
        "leftarrow card rightarrow"
        ". . .";
    grid-template-rows: 10vh 88vh 2vh;
    // grid-template-columns: 150px 600px 150px;
    // grid-gap: 10px;
    // margin: auto;
    // width: 1000px;
}

@media(max-width: 472px) {
    .mixinCard {
        width: 98vw;
        height: 80vh;
        max-height: 100%;
    }
}

@media(min-width: 472px) and (max-width: 800px) {
    .mixinCard {
        height: 80vh;
        width: calc(80vh * var(--ratio))
    }
}

@media(min-width: 800px) {
    .mixinCard {
        margin: 0px 30px;
        height: 88vh;
        width: calc(88vh * var(--ratio))
    }
}

.button-FastForward{
    font-size: 12pt;
    cursor: pointer;
    top: 10px;
    width: 120px;
    height: 40px;
    padding: 6px;
}

.button-FastForward-up{
    grid-area: uparrow;
}

.button-FastForward-left{
    grid-area: leftarrow;
    justify-self: end;
}

.button-FastForward-right{
    grid-area: rightarrow;
    justify-self: baseline;
}

.timer, .currentPoints, .cardsCounter {
    margin: 0 10px;
    width: 400px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
}

.timer{
    grid-area: timer;
    justify-self: baseline;
    align-self: end;
    justify-content: flex-start;
}

.currentPoints{
    grid-area: currentpoints;
    justify-self: center;
    align-self: end;
}

.cardsCounter{
    grid-area: cardsleft;
    justify-self: end;
    align-self: end;
    justify-content: flex-end;
}

.companyProfile {
    top: 0;
    z-index: 5;
    position: sticky;
    border-top-left-radius: 20px; // compatibility
    border-top-right-radius: 20px;
    background: rgb(228,227,227);
    background: linear-gradient(90deg, rgba(228,227,227,1) 16%, rgba(237,236,236,1) 47%, rgba(246,244,244,1) 77%);
    &-color {
        &-red{
            background: rgba(231, 139, 139, 0.25);
        }
        &-green{
            background: rgba(128, 206, 132, 0.25);
        }
        &-yellow{
            background: rgba(195, 202, 90, 0.25);
        }
        &-pink{
            background: rgba(226, 148, 167, 0.25);
        }
        &-blue{
            background: rgba(135, 193, 211, 0.25);
        }
    }
}

.companyProfile-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 86px;
    width: 86px;
}

.companyProfile-logo img{
    max-height: 100%;
    max-width: 100%;
    min-height: 60%;
    min-width: 60%;
    border-radius: 4.5px;
}

.companyProfile-content{
    margin: 0 15px;
    padding: 10px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.companyProfile-info{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 2px 1px;
    margin-left: 20px;
    max-width: calc(100% - 108px);
}

.companyProfile-info > div:not(:first-child) {
    margin-top: 6px;
}

.companyProfile-info h3{
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.companyProfile-info span{
    font-size: 0.95em;
    font-weight: 300;
}

.companyProfile-info h3, .companyProfile-info span{
    font-family: 'Quicksand', sans-serif;
}

.companyProfile-content-item-sector > span {
    font-size: 1.1em;
    font-weight: 400;
}

@media(max-width: 472px) {
    .companyProfile-info h3{
        font-size: 1.1em;
    }

    .companyProfile-info span{
        font-size: 0.8em;
    }

    .companyProfile-content-item-sector > span{
        font-size: 0.9em;
    }
}

.item-covered{
    height: 100%;
}

.item-locked{
    border-radius: 4.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform .15s; 
    z-index: 1;
    background: #FAFAFA;
    box-shadow: 0 0 3.5px rgba(0,0,0,0.5);;
}

.item-locked span{
    font-family: 'Quicksand', sans-serif;
    font-size: 0.95em;
    font-weight: 600;
    text-transform: uppercase;
    color: #5B5B5B;
    word-break: break-word;
    margin: 8px;
    text-align: center;
}
  
.item-locked:hover {
    transform: scale(1.025);
}

.item-covered-companyProfile-logo{
    align-self: center;
    height: 86px;
    width: 86px;
}

.item-covered-companyProfile-content-item-name{
    height: 48px;
}

.item-covered-companyProfile-content-item-sector{
    height: 24px;
}

.companyProfile-content-item-name{
    min-height: 48px;
}

.companyProfile-content-item-sector{
    min-height: 24px;
}

.financials h2, .technicals h2{
    font-size: 1.3em;
    font-weight: 300;
    font-family: 'Mukta Malar', sans-serif;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.financials span {
    font-size: 14pt;
    font-weight: 200;
    font-family: 'Mukta Malar', sans-serif;
}

.lock-icon-sm{
    height: 35%;
}

.lock-icon-md{
    height: 55%;
}

.lock-icon-xl{
    height: 75%;
}

.lock-icon svg{
    display: block;
    width: 100%;
    height: 100%;
}

.lock-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-covered-quotesChart{
    height: 30.5%;
}

.technicals{
    display: block;
}

.technicals-datechanger{
    display: grid;
    grid-template-areas: 
    "slider slider slider"
    "description query currentval";
    grid-template-rows: 40px 40px;
    grid-template-columns: 45% 33% 22%;
    &[disabled]{
        pointer-events: none;
        opacity: 0.5;
    }
}

.technicals-datechanger-slider{
    grid-area: slider;
    margin: 0 12px 0 12px;
}

.technicals-datechanger-currentval{
    grid-area: currentval;
    display: flex;
    align-items: center;
    justify-content: center;
}

.technicals-datechanger-description{
    grid-area: description;
    text-align: center;
    margin-left: 10px;
}

.technicals-datechanger-description > span{
    font-size: 12px;
    color: grey;
}

.technicals-datechanger-description-helper {
    grid-area: qm;
    display: none;
    margin-top: 2px;
}

.technicals-datechanger-description-helper > svg {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 50%;
}

.technicals-datechanger-fetcher{
    grid-area: query;
    justify-self: right;
}

.technicals-datechanger-fetcher > button{
    width: 120px;
    background: transparent;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;
}

.technicals-datechanger-fetcher > button:active {
    transform: translateX(0%) translateY(0%) scale(0.98);
  }

.technicals-datechanger-currentval > span {
    margin-bottom: 5px;
    word-break: break-all;
}

.technicals-datechanger-currentval-animated {
    animation: changeDaysColor 2.5s ease-out; 
}

@keyframes changeDaysColor {
    0% { color: rgb(29, 143, 9)}
    25% { color: rgb(34, 128, 17)}
    50% { color: rgb(41, 107, 29)}
    75% { color: rgb(35, 68, 30)}
    100% { color: rgb(0, 0, 0) }
}

.technicals-indicators{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 12px;
}

.technicals-indicators > div:first-child{
    margin-top: 10px;
}

.item-covered-technicals-title{
    height: 40px;
    width: 100px;
    margin: 10px 0;
}

.item-covered-technicals-datechanger{
    height: 80px;
}

.item-covered-technicals-indicators{
    height: 300px;
}

.item-covered-technicals-indicators-item{
    height: 30px;
    margin-top: 3px;
    &:first-child{
        height: 36px;
    }
}

.item-covered-technicals-row{
    height: 20px;
    font-size: 16px;
}

.technicals-indicators-tablecell-highlighted{
    background-color: rgb(250, 248, 246);
    border-bottom: 1px solid  rgb(178, 185, 196) !important;
    color: rgb(71, 139, 170) !important;
}

.technicals-indicators-tablecell-positive{
    color: rgb(29, 143, 9) !important;
}

.technicals-indicators-tablecell-positive::after{
    content: " ↑" !important;
}

.technicals-indicators-tablecell-negative{
    color: #FF0000 !important;
}

.technicals-indicators-tablecell-negative::after{
    content: " ↓" !important;
}

.modal-stocks-inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-stocks-inner h2 {
    margin: 0;
    font-size: 1.35em;
    text-align: center;
    font-weight: 500;
    font-family: 'Mukta Malar', sans-serif;
}

.currentPoints, .cardsCounter, .timer {
    align-self: center;
    color: lightgray;
}

.currentPoints h2, .cardsCounter h2, .timer h2{
    margin: 0;
    font-size: 22pt;
    font-weight: 400;
    font-family: 'Karma', serif;
}

.sentinel-loading {
    grid-area: currentpoints;
    text-align: center;
    color: lightgray;
    font-size: 20px;
}

@media(max-width: 600px), (max-height: 750px) {
    .technicals-datechanger {
        display: grid;
        grid-template-areas: 
        "slider slider slider"
        "qm query currentval";
        grid-template-rows: 50px 50px;
        grid-template-columns: 28% 44% 28%;
    }

    .item-covered-technicals-datechanger{
        height: 100px;
    }

    .technicals-datechanger-description {
        display: none;
    }

    .technicals-datechanger-description-helper {
        display: block;
        justify-self: center;
    }

    .technicals-datechanger-fetcher {
        justify-self: center;
    }

    .technicals-datechanger-currentval {
        margin-right: 10px;
    }

    .technicals-datechanger-currentval > span {
        margin-bottom: 12px;
    }
}

@media(max-width: 800px) {
    .game {
        max-width: 100%;
        grid-template-areas:
        "cardsleft currentpoints timer"
        "card card card"
        ". arrows .";
        
        grid-template-columns: 10vw 80vw 10vw;
        grid-template-rows: 6vh 80vh 16vh;
    }

    .button-FastForward-left, .button-FastForward-right {
        grid-area: arrows;
    }

    .currentPoints, .cardsCounter, .timer{
        align-self: center;
        align-items: center;
        & > h2 {
            font-size: 22px;
        }
        max-height: 100%;
    }

    .currentPoints{
        justify-self: center;
        justify-content: center;
    }
    
    .cardsCounter {
        justify-self: start;
        justify-content: flex-start;
        & > h2 {
            margin-left: 15px;
        }
    }

    .timer {
        justify-self: end;
        justify-content: flex-end;
        margin-right: 15px;
        & > h2 {
            margin-right: 15px;
        }
    }

    .button-FastForward-left, .button-FastForward-right {
        align-self: center;
    }

    .button-FastForward-left{
        justify-self: start;
        margin-left: 30px;
    }

    .button-FastForward-right{
        justify-self: end;
        margin-right: 30px;
    }
}

@media(min-width: 1335px) {
    .currentPoints, .cardsCounter, .timer {
        background: rgb(246, 246, 246);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
        justify-content: center;
        color: rgb(77, 77, 77);
        // text-shadow: 1px 1px 2px rgb(184, 184, 184);
        position: relative;

        & > h2{
            margin-top: 5px;
        }

        &::after {
            border-top-left-radius: 60px; 
            border-top-right-radius: 60px;
            background: #BDE0FE;
            background: linear-gradient(to right, #FFC8DD 20%,  #FFAFCC 40%, #CDB4DB 60%, #A2D2FF 80%, #BDE0FE 100%);
            position: absolute;
            content: '';
            height: 5px;
            width: 100%;
            top: 0;
        }
    }
}

.button-GameControl{
    display: none;
}

@media(min-width: 800px) {
    .button-GameControl {
        display: block;
        position: absolute;
        bottom: 10px;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        cursor: pointer;
        text-align: center;
        height: 40px;
        width: 110px;
        text-transform: uppercase;
        border-style: solid;
        border-width: 0.5px;
        border-radius: 20px;
        background: transparent;
        transition: 0.4s ease-in-out;
        outline: none;
        &:hover{
            color: rgb(94, 94, 94);
        }
    }

    .button-GameControl-GameExit {
        left: 40px;
        margin: 10px;
        color: rgb(228, 67, 38); 
        box-shadow: 0 0 4px rgb(219, 118, 118);           
        border-color: rgb(228, 67, 38); 
        &:hover {
            background-color: rgb(228, 67, 38); 
            border-color: rgb(228, 67, 38); 
        }
    }

    .button-GameControl-FullScreenControl {
        right: 40px;
        height: 40px;
        margin: 10px;
        color: rgb(223, 221, 118); 
        box-shadow: 0 0 4px rgb(221, 215, 164);           
        border-color: rgb(223, 221, 118); 
        &:hover {
            background-color: rgb(223, 221, 118); 
            border-color: rgb(223, 221, 118); 
        }
    }
}

