
  .arrow {
    cursor: pointer;
    height: 120px;
    transform: translateX(0%) translateY(0%);
    transition: transform .15s;
    width: 80px;
    
    $transition-time: .15s;
    
    &-top,
    &-bottom {
      background-color: rgb(148, 148, 148);
      height: 4px;
      position: absolute;
      top: 50%;
      width: 100%;

      &-right{
        left: -12px;
        &:after{
            background-color: #2dd610;
        }
      }

      &-left{
        left: 12px;
        &:after{
            background-color: #e90f0f;
        }
      }

      &-left:after, &-right:after{
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        transition: all $transition-time;
      }
    }
    
    &-top {
      &-right{
        transform: rotate(60deg);
        transform-origin: bottom right;
      }

      &-left{
        transform: rotate(60deg);
        transform-origin: top left;
      }
      
      &-right:after, &-left:after {
        left: 100%;
        right: 0;
      }

      &-right:after{
        transition-delay: 0s;
      }

      &-left::after{
        transition-delay: $transition-time;
      }
    }
    
    &-bottom {
      &-right{
        transform: rotate(-60deg);
        transform-origin: top right;
      }

      &-left{
        transform: rotate(-60deg);
        transform-origin: bottom left;
      }
      
      &-right:after, &-left:after {
        left: 0;
        right: 100%;
      }

      &-right:after{
        transition-delay: $transition-time;
      }

      &-left:after{
        transition-delay: 0s;
      }

    }
    
    &:hover & {
      
      &-top-right:after, &-top-left:after {
        left: 0;
      }

      &-top-right:after {
        transition-delay: $transition-time;
      }
  
      &-bottom-right:after, &-bottom-left:after {
        right: 0;
      }

      &-bottom-right:after {
        transition-delay: 0s;
      }

      &-bottom-left:after {
        transition-delay: $transition-time;
      }

      &-top-left:after {
        transition-delay: 0s;
      }
    }
    
    &:active {
      transform: translateX(0%) translateY(0%) scale(0.85);
    }
  }

  .arrow-small {
    display: none;
  }

  .arrow-small-button {
    cursor: pointer;
    outline: none;
    height: 10vh;
    width: 10vh;
    max-width: 75px;
    max-height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border-style: solid;
    border-width: 3px;
    &:hover, :active {
        border-color: transparent;
        color: rgb(25, 37, 54);
    }
  }

  .arrow-small-right-button {
    border-color: #2dd610;
    color: #2dd610;
    &:hover, :active{
      background-color: #2dd610;
    }
  }

  .arrow-small-left-button {
    border-color: #e90f0f;
    color: #e90f0f;
    &:hover, :active{
      background-color: #e90f0f;
    }
  }

  @media(max-width: 800px) {
    .arrow {
      display: none;
    }

    .arrow-small {
      display: block;
    }
  }